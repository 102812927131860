import React from 'react';
import { bool } from 'prop-types';

const Arrow = ({ right, tailed, ...props }) => {
  const style = {
    transform: right ? 'rotate(180deg)' : '',
  };
  return (
    <>
      {tailed ? (
        <svg
          width="54"
          height="24"
          viewBox="0 0 54 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          style={style}
          {...props}
        >
          <g>
            <path d="M50 13H51V11H50V13ZM0 13H50V11H0V13Z" />
            <path
              d="M41.5353 1.46432L52.1419 12.0709L41.5353 22.6775"
              stroke="black"
              fill="transparent"
              strokeWidth="2"
            />
          </g>
        </svg>
      ) : (
        <svg
          width="28"
          height="23"
          viewBox="0 0 28 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
          {...props}
        >
          <path
            d="M25 12.5356H26V10.5356H25V12.5356ZM0 12.5356H25V10.5356H0V12.5356Z"
            fill="currentColor"
          />
          <path
            d="M15.5353 0.999844L26.1419 11.6064L15.5353 22.213"
            stroke="currentColor"
            strokeWidth="2"
          />
        </svg>
      )}
    </>
  );
};

Arrow.propTypes = {
  right: bool,
  tailed: bool,
};

Arrow.defaultProps = {
  right: false,
  tailed: false,
};

export default Arrow;
